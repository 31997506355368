import Seo from 'src/components/seo/Seo'

import Circula from '../../components/Circula/Circula'

function Page() {
  return (
    <>
      <Seo language="pt-br" />
      <Circula />
    </>
  )
}

export default Page
